import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react'

import { Link } from 'gatsby'

const AverageCallInfoModal = ({ isOpen, onClose, info }) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-bold text-white bg-blue text-md font-heading">
          Call request sent!
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            Right now the average wait for an ATSG member to get a callback is{' '}
            {info?.average}
            and the longest waiting time is {info?.longestCall}{' '}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button as={Link} variant="lightBlue" to="/call-history" mr={3}>
            VIEW CALL HISTORY
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AverageCallInfoModal
