import React from "react";
import { Container } from 'starterUiComponents'
import { chakra } from '@chakra-ui/react'
import { Link } from "gatsby";

const NotEnoughPermissions = ({ reason }) => {

    return (
        <div>
            <Container size="md">
                <chakra.h2 className="mb-10 text-center uppercase text-l2">
                    UH OH! YOU ARE CURRENTLY NOT ALLOWED TO ACCESS THIS PAGE
                </chakra.h2>
                {reason && <p>{reason}</p>}
                <Link
                    to="/account"
                    className="relative text-base uppercase font-heading ml-2"
                >
                    GO TO ACCOUNT PAGE
                </Link>
            </Container>
        </div>
    )
}

export default NotEnoughPermissions;