import { DeleteIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'

import { useAuth } from '@/contexts/AuthContext'
import ApiFetch from '@/utils/api'
import { getRole, ROLES } from '@/utils/Roles'

import { FormElement, FormSection } from '@/components/forms'
import { Container } from '@/components/ui-components'
import AverageCallInfoModal from '../components/average-call-info-modal'

const FormSectionTitle = ({ title, subtitle }) => (
  <Box mb={7}>
    <Box className="mb-2 font-bold font-heading">{title}</Box>
    <Box className="text-sm">{subtitle}</Box>
  </Box>
)

const reducer = (state, action) => {
  switch (action.type) {
    case 'submitting':
      return {
        ...state,
        status: 'submitting',
        validationErrors: null,
        error: null,
      }
    case 'submitted':
      return {
        ...state,
        status: 'submitted',
        validationErrors: null,
        error: null,
        codes: [
          {
            id: 'first',
            key: '',
            value: '',
          },
        ],
      }
    case 'validationErrors':
      return {
        ...state,
        validationErrors: action.payload.validationErrors,
        status: 'validationErrors',
      }
    case 'setCodes':
      return {
        ...state,
        codes: action.payload.codes,
      }
    case 'reset':
      return {
        ...state,
        validationErrors: null,
        error: null,
        codes: [
          {
            id: 'first',
            key: '',
            value: '',
          },
        ],
      }
    case 'error':
      return {
        ...state,
        error: action.payload.error,
        status: 'error',
      }
    default:
      throw new Error(`Call Request - Unssupported action type ${action.type}`)
  }
}

const MemberTechCallScren = () => {
  const { currentUser } = useAuth()
  const role = React.useMemo(() => getRole(currentUser), [currentUser])
  const isRecycler = React.useMemo(() => role === ROLES.RECYCLER, [role])
  const [techniciansAvailable, setTechniciansAvailable] = React.useState([])
  const [averageCallInfo, setAverageCallInfo] = React.useState(null)

  //TODO: the technameRef.current.value update
  // useEffect(() => {
  //   if (!loading && currentUser === null) {
  //     navigate('/sign-in')
  //   }
  //   technameRef.current &&
  //     (technameRef.current.value = isRecycler
  //       ? ''
  //       : currentUser?.shopData?.contact ?? '')
  // }, [currentUser, loading])

  const getAvailableTechnicians = async () => {
    try {
      const response = await ApiFetch({
        path: `/staff/available`,
      })

      if (response.data) {
        const _techs = response.data.rows.sort((tech1, tech2) =>
          tech1.name.localeCompare(tech2.name)
        )
        setTechniciansAvailable(_techs)
      }
    } catch {
      setTechniciansAvailable([])
    }
  }
  useEffect(() => {
    getAvailableTechnicians()
  }, [])

  const [state, dispatch] = React.useReducer(reducer, {
    status: 'idle',
    error: null,
    validationErrors: null,
    codes: [
      {
        id: 'first',
        key: '',
        value: '',
      },
    ],
  })
  const { codes } = state

  const onAddCodeInput = (e) => {
    e.preventDefault()
    dispatch({
      type: 'setCodes',
      payload: {
        codes: [
          ...state.codes,
          {
            id: Date.now(),
            key: 'option1',
            value: '',
          },
        ],
      },
    })
  }

  const onRemoveCodeInput = (id, event) => {
    event.preventDefault()
    const newCodes = state.codes.filter((code) => code.id !== id)
    dispatch({
      type: 'setCodes',
      payload: {
        codes: newCodes,
      },
    })
  }

  const onHandleCodeInputChange = (i, event) => {
    event.preventDefault()
    let _codes = [...codes]
    _codes.map((code) => {
      if (code.id === i) {
        code.value = event.target.value
      }
      return code
    })
    dispatch({
      type: 'setCodes',
      payload: {
        codes: _codes,
      },
    })
  }

  const onHandleCodeSelectChange = (i, event) => {
    event.preventDefault()
    let _codes = [...codes]
    _codes.map((code) => {
      if (code.id === i) {
        code.key = event.target.value
        if (code.key === '') {
          code.value = ''
        }
      }
      return code
    })
    dispatch({
      type: 'setCodes',
      payload: {
        codes: _codes,
      },
    })
  }

  const renderCodesUI = (props) => {
    return codes.map((code) => (
      <HStack mb={2} {...props} key={code.id}>
        <Select
          layerStyle="input"
          maxW="180px"
          value={code?.key ?? 'noObd2'}
          onChange={(e) => onHandleCodeSelectChange(code.id, e)}
          placeholder="Select Type"
        >
          <option value="noObd2">Not OBDII</option>
          <option value="pCodes">P-codes</option>
          <option value="bCodes">B-codes</option>
          <option value="cCodes">C-codes</option>
          <option value="uCodes">U-codes</option>
        </Select>
        {code.id !== 'first' && (
          <DeleteIcon
            cursor="pointer"
            onClick={(e) => onRemoveCodeInput(code.id, e)}
          />
        )}
        <Input
          id={code.id}
          placeholder="Code Number"
          maxW="270px"
          className="js-code-number"
          value={code.value && code.key !== '' ? code.value : ''}
          onChange={(e) => onHandleCodeInputChange(code.id, e)}
          disabled={
            !codes ||
            !codes.length ||
            (codes.length === 1 && codes[0].key === '')
          }
        />
      </HStack>
    ))
  }

  const scrollToRef = (ref) => {
    ref.current.focus()
    window.scrollTo(
      0,
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    )
    // indow.scrollTo(0, this.myRef.current.offsetTop)
  }

  const reset = () => {
    technameRef.current.value = ''
    contactMethodRef.current.value = isRecycler
      ? ['callAlternativeNumber']
      : ['callDefaultShop']
    phoneNumberRef.current.value = ''
    yearRef.current.value = ''
    makeRef.current.value = ''
    modelRef.current.value = ''
    transRef.current.value = ''
    mileageRef.current.value = ''
    commentRef.current.value = ''
    idleVoltageRef.current.value = ''
    idlePresureRef.current.value = ''
    stallPresureRef.current.value = ''
    atsgtechreqRef.current.value = ''
    techAvailabilityRef.current.value = ''
    probStringRef.current.value = 'scanner'
    targetShopNameRef.current.value = ''
    // targetShopPhoneNumberRef.current.value = ''
  }

  const onFormReset = (e) => {
    e.preventDefault()
    reset()
    dispatch({ type: 'reset' })
  }

  const formValidated = (formData) => {
    let { year, make, model, trans, mileage, comment } = formData
    const errors = {}

    if (!year) {
      errors.year = {
        field: 'year',
        message: 'Year is required',
        ref: yearRef,
      }
    } else {
      if (year < 1941 || year.length !== 4 || isNaN(year)) {
        errors.year = {
          field: 'year',
          message: '4 digits, later than 1940',
          ref: yearRef,
        }
      }
    }
    if (!make) {
      errors.make = {
        field: 'make',
        message: 'Make is required',
        ref: makeRef,
      }
    }
    if (!model) {
      errors.model = {
        field: 'model',
        message: 'Model is required',
        ref: modelRef,
      }
    }
    if (!trans) {
      errors.trans = {
        field: 'trans',
        message: 'Transmission is required',
        ref: transRef,
      }
    }
    if (isNaN(mileage)) {
      errors.mileage = {
        field: 'mileage',
        message: 'Mileage needs to be a number',
        ref: mileageRef,
      }
    }
    if (!comment) {
      errors.comment = {
        field: 'comment',
        message: 'Comment are required',
        ref: commentRef,
      }
    }

    if (Object.values(errors).length) {
      dispatch({
        type: 'validationErrors',
        payload: {
          validationErrors: errors,
        },
      })
      scrollToRef(Object.values(errors)[0].ref)
      return false
    }
    return true
  }

  const technameRef = React.useRef()
  // We need this to build staff on the backend
  const contactMethodRef = React.useRef(null)
  // companies call number
  const phoneNumberRef = React.useRef(null)
  const yearRef = React.useRef(null)
  const makeRef = React.useRef(null)
  const modelRef = React.useRef(null)
  const transRef = React.useRef(null)
  const mileageRef = React.useRef(null)
  const commentRef = React.useRef(null)
  const idleVoltageRef = React.useRef(null)
  const idlePresureRef = React.useRef(null)
  const stallPresureRef = React.useRef(null)
  const atsgtechreqRef = React.useRef(null)
  const techAvailabilityRef = React.useRef(null)
  const probStringRef = React.useRef(null)
  const targetShopNameRef = React.useRef(null)
  // const targetShopPhoneNumberRef = React.useRef(null)

  const retrieveFormValues = () => {
    return {
      /* CONTACT DATA */
      technam: technameRef.current.value,
      // Default shop number (same name that DB)
      listphone: currentUser?.shopData?.shopPhone,
      diffphone: phoneNumberRef?.current?.value?.trim() ?? null,
      // Shop alternative method, or target shop for salvage yard
      contactMethod: contactMethodRef?.current?.value ?? ['callDefaultShop'],
      contactMethodValues: {
        callDefaultShop: currentUser?.shopData?.phoneNumber,
        emailShop: currentUser?.shopData?.companyEmail,
        emailPerson: currentUser?.shopData?.userEmail,
        callAlternativeNumber: phoneNumberRef?.current?.value ?? '',
      },
      // for recyclers
      targetShopName: targetShopNameRef?.current?.value,

      /* VEHICLE DATA */
      year: yearRef.current.value.trim(),
      make: makeRef.current.value.trim(),
      model: modelRef.current.value.trim(),
      trans: transRef.current.value.trim(),
      mileage: mileageRef.current.value.trim(),
      comment: commentRef.current.value.trim(),

      /* PROBLEM DETAILS */
      idleVoltage: idleVoltageRef.current.value.trim(),
      idlePresure: idlePresureRef.current.value.trim(),
      stallPresure: stallPresureRef.current.value.trim(),

      probstring: probStringRef?.current?.value ?? 'scanner',
      shop_name: currentUser?.shopData?.shop_name,
      sub_num: currentUser?.shopData?.sub_num,

      /* CODES */
      codes: codes.map((code) => ({
        value: code.value,
        key: code.key,
      })),

      /* REQUEST A TECHNICIAN */
      atsgtechreq: atsgtechreqRef?.current?.value ?? 'any',
      techAvailability: techAvailabilityRef?.current?.value ?? 'any',
    }
  }

  // ñapa xD
  const setTechnicianAvailability = (value) => {
    techAvailabilityRef.current.value = value
  }
  // ñapa xD
  const setContactMethods = (value) => {
    contactMethodRef.current.value = value
  }
  const onFormSubmit = async (e) => {
    e.preventDefault()
    dispatch({ type: 'submitting' })
    const formData = retrieveFormValues()
    if (!formValidated(formData)) {
      return
    }

    try {
      const response = await ApiFetch({
        method: 'POST',
        path: `/techcalls`,
        body: formData,
        userToken: currentUser?.shopData?.idToken,
      })

      if (response.code === 200) {
        dispatch({ type: 'submitted' })
        // navigate('/call-history')
        setAverageCallInfo({
          average: response.result.average,
          longestCall: response.result.longestCall,
        })
      } else if (response.errors && response.errors.length) {
        dispatch({
          type: 'validationErrors',
          payload: {
            validationErrors: response.errors,
          },
        })
      }
    } catch (error) {
      dispatch({
        type: 'error',
        payload: {
          error: error.message,
        },
      })
    }
  }

  return (
    <Container>
      <FormSectionTitle
        title="Subscriber and Contact Information:"
        subtitle="Please make sure this is the correct shop, then fill in the information."
      />

      <Box>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="space-between"
          className="text-white bg-darkBlue p-7"
        >
          <Box
            className="mb-4 font-bold font-heading text-md sm:mb-0 "
            sx={{ p: { mb: 0 } }}
          >
            <Text>Subscriber Number: {currentUser?.shopData?.sub_num}</Text>
            <Text>Shop Name: {currentUser?.shopData?.shop_name}</Text>
          </Box>
          <Stack direction={{ base: 'column', lg: 'row' }}>
            <Box maxW="250px" className="text-sm" mb={[4, 0]}>
              If this is a callback on a previous call, please use the call
              history.
            </Box>
            <Button as={Link} variant="lightBlue" to="/call-history">
              VIEW CALL HISTORY
            </Button>
          </Stack>
        </Stack>
        <form action="">
          <FormSection>
            <FormElement
              id="technam"
              label={
                isRecycler
                  ? 'Who should we ask for when we call the purchasing shop?'
                  : 'Who should we ask for when we respond?'
              }
              type="input"
            >
              <Input placeholder="Person Name" ref={technameRef} />
            </FormElement>
            <FormElement
              id="contact-method"
              label="How would you like to be contacted:"
            >
              <CheckboxGroup
                defaultValue={
                  isRecycler ? ['callRecyclerShop'] : ['callDefaultShop']
                }
                onChange={setContactMethods}
              >
                <VStack align="flex-start" spacing={4} ref={contactMethodRef}>
                  <Checkbox value="callDefaultShop">
                    Call the shop: {currentUser?.shopData?.shopPhone}
                  </Checkbox>

                  <Checkbox value="emailShop">
                    E-Mail the shop: {currentUser?.shopData?.companyEmail}
                  </Checkbox>
                  <Checkbox value="emailPerson">
                    E-Mail me: {currentUser?.shopData?.userEmail}
                  </Checkbox>
                  {!isRecycler && (
                    <>
                      <Checkbox value="callAlternativeNumber">
                        Call me at this number (add country code if applicable):
                      </Checkbox>
                      <Input
                        placeholder="+X (XXX) XXX-XXXX"
                        ref={phoneNumberRef}
                      />
                      <FormHelperText fontSize="12px" color="text">
                        The number MUST be listed in your shops information or
                        the shop MAIN line will be used.
                      </FormHelperText>
                    </>
                  )}
                  {isRecycler && (
                    <>
                      <Checkbox value="callRecyclerShop">
                        Call the target shop at this number (add country code if
                        applicable):
                      </Checkbox>
                      <Input
                        placeholder="+X (XXX) XXX-XXXX"
                        ref={phoneNumberRef}
                      />
                      <FormHelperText fontSize="12px" color="text">
                        The number MUST be listed in your shops information or
                        the shop MAIN line will be used.
                      </FormHelperText>
                    </>
                  )}
                </VStack>
              </CheckboxGroup>
            </FormElement>
            {isRecycler && (
              <FormElement
                id="target-shop-name"
                label="Enter the name of the shop"
                type="input"
              >
                <Input placeholder="Target Shop Name" ref={targetShopNameRef} />
              </FormElement>
            )}
          </FormSection>

          {/*  Vehicle, Transmission, and Problem Information */}
          <FormSectionTitle
            title="Vehicle, Transmission, and Problem Information"
            subtitle="Please fill in any information you have."
          />

          {/* Vehicle info section */}
          <FormSection title="Vehicle Information" bgColor="blue">
            <FormElement
              type="input"
              label="Year:"
              id="year"
              required={true}
              isInvalid={state?.validationErrors?.year}
            >
              <Input
                placeholder='Four Numbers, example "1995" or "2001"'
                errorBorderColor="red"
                ref={yearRef}
              />
              {/* <FormErrorMessage className="text-red-500">Errores</FormErrorMessage> */}
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.year?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="make"
              label="Make:"
              required={true}
              type="input"
              isInvalid={state?.validationErrors?.make}
            >
              <Input
                placeholder='Manufacturer, example "Ford" or "BMW"'
                ref={makeRef}
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.make?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="model"
              label="Model:"
              required={true}
              type="input"
              isInvalid={state?.validationErrors?.model}
            >
              <Input
                placeholder='Example:  "Probe" or "740i"'
                ref={modelRef}
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.model?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="trans"
              label="Transmission Model:"
              required={true}
              type="input"
              isInvalid={state?.validationErrors?.trans}
            >
              <Input
                placeholder='Example:  "CD4E" or "5HP-30"'
                ref={transRef}
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.trans?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="mileage"
              label="Mileage:"
              type="input"
              isInvalid={state?.validationErrors?.mileage}
            >
              <Input
                placeholder="Enter full mileage number"
                ref={mileageRef}
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.mileage?.message}
              </FormErrorMessage>
            </FormElement>
          </FormSection>

          {/* Problem details */}
          <FormSection title="Problem Details" bgColor="blue">
            <FormElement
              id="comment"
              required={true}
              label="Please explain your problem:"
              sx={{ label: { mt: 3 } }}
              isInvalid={state?.validationErrors?.comment}
            >
              <Textarea
                placeholder="Please describe the issues you have discovered."
                ref={commentRef}
                height="204px"
                errorBorderColor="red"
              />
              <FormErrorMessage style={{ color: 'red' }}>
                {state.validationErrors?.comment?.message}
              </FormErrorMessage>
            </FormElement>
            <FormElement
              id="system-voltage"
              label="What is the System Voltage?"
              type="input"
            >
              <Input placeholder="System voltage" ref={idleVoltageRef} />
            </FormElement>
            <FormElement
              id="line-pressure-idle"
              label="What is the line pressure at idle?"
              type="input"
            >
              <Input placeholder="Line pressure at idle" ref={idlePresureRef} />
            </FormElement>
            <FormElement
              id="line-pressure-stall"
              label="What is the line pressure at stall?"
              type="input"
            >
              <Input
                placeholder="Line pressure at stall"
                ref={stallPresureRef}
              />
            </FormElement>
          </FormSection>

          {/* Codes */}
          <FormSection title="codes" bgColor="blue">
            <FormElement id="problem" label="What seems to be the problem?">
              <RadioGroup defaultValue="scanner" ref={probStringRef}>
                <VStack align="flex-start" spacing={4}>
                  <Radio value="scanner">
                    Scanner obtained codes (list codes below)
                  </Radio>
                  <Radio value="manual">
                    Manually Obtained Codes (list codes below)
                  </Radio>
                  <Radio value="not-attempted">
                    No Code Retrieval was attempted
                  </Radio>
                  <Radio value="no-codes-stored">
                    Code retrieval indicated "No Codes Stored"
                  </Radio>
                  <Radio value="not-done">
                    Code retrieval was attempted but could not be done
                  </Radio>
                </VStack>
              </RadioGroup>
            </FormElement>

            <FormElement
              label="Please enter codes if any:"
              mt={10}
              sx={{ label: { mt: 4 } }}
            >
              {renderCodesUI()}
              <Box
                as="button"
                className="mt-4 text-sm font-bold uppercase transition duration-200 ease-in-out cursor-pointer text-lightBlue hover:text-darkBlue"
                onClick={onAddCodeInput}
              >
                + add another code
              </Box>
            </FormElement>
          </FormSection>

          {/* Request a tech */}
          <FormSection title="Request a Technician" bgColor="blue">
            <FormElement
              type="input"
              id="technician"
              label="Specific ATSG Technician Requested:"
            >
              <Select
                placeholder="Any ATSG Technician"
                layerStyle="input"
                ref={atsgtechreqRef}
              >
                {techniciansAvailable &&
                  techniciansAvailable.map((tech, i) => (
                    <option key={i} value={tech.name}>
                      {tech.name.toUpperCase()}
                    </option>
                  ))}
              </Select>
            </FormElement>
            <FormElement
              id="not-available"
              label="If this technician is not available:"
            >
              <RadioGroup
                defaultValue="any"
                onChange={setTechnicianAvailability}
                ref={techAvailabilityRef}
              >
                <VStack align="flex-start" spacing={4}>
                  <Radio value="wait">
                    I will wait (May cause delayed response time)
                  </Radio>
                  <Radio value="any">
                    Please send me to any ATSG Technician
                  </Radio>
                </VStack>
              </RadioGroup>
            </FormElement>
          </FormSection>
          <Flex
            align={{ base: 'flex-end', sm: 'flex-end' }}
            flexDirection="column"
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={5}
              sx={{ button: { width: 200 } }}
            >
              <Button variant="outlineDark" onClick={onFormReset}>
                Reset
              </Button>
              <Button
                variant="lightBlue"
                onClick={onFormSubmit}
                disabled={state.status === 'submitting'}
              >
                Submit{state.status === 'submitting' && 'ting'}
              </Button>
            </Stack>
            <Stack paddingBlockStart={3}>
              {state.status === 'submitted' && (
                <Alert status="success">
                  <AlertIcon />
                  Call request succesfully submitted!
                </Alert>
              )}

              {state.error && (
                <Alert status="error">
                  <AlertIcon />
                  An error happened! {state.error}
                </Alert>
              )}
            </Stack>
          </Flex>
        </form>
      </Box>
      <AverageCallInfoModal
        isOpen={!!averageCallInfo}
        onClose={() => setAverageCallInfo(null)}
        info={averageCallInfo}
      />
    </Container>
  )
}

export default MemberTechCallScren
