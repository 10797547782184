import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { useAuth } from '@/contexts/AuthContext'

import { getRole, hasPermission, PERMISSIONS, ROLES } from '@/utils/Roles'
import useNeedsMembership from '@/hooks/useNeedsMembership'
import Loader from '@/components/ui-components/Loader'
import RecyclerTechCallScreen from '@/components/screens/recycler-tech-call'
import MemberTechCallScren from '@/components/screens/member-tech-call'
import { getPermissionDeniedReason } from 'src/utils/Roles'
import NotEnoughPermissions from 'src/components/NotEnoughPermissions'

const CallRequestPage = () => {
  const { currentUser, loading } = useAuth()
  const role = getRole(currentUser)
  const isRecycler = React.useMemo(() => role === ROLES.RECYCLER, [role])
  const showLoaderPage = useNeedsMembership()

  useEffect(() => {
    if (!loading && currentUser === null) {
      navigate('/sign-in')
    }
  }, [currentUser, loading])

  // Logged in is checked on another page, maybe we could add that here also in the future!
  const permission = hasPermission(currentUser, PERMISSIONS.MEMBERSHIP_CALLS)
  if (!permission && !loading) {
    const { reason } = getPermissionDeniedReason(
      currentUser,
      PERMISSIONS.MEMBERSHIP_CALLS
    )
    return <NotEnoughPermissions reason={reason} />
  }

  if (showLoaderPage || !currentUser || !role) {
    return <Loader />
  }

  if (isRecycler) {
    return <RecyclerTechCallScreen />
  } else {
    return <MemberTechCallScren />
  }
}

export default CallRequestPage
